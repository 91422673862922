import * as React from "react"
import Layout from "../components/layout"

let colourScale = {0.0:[48,48,48],
    0.0039:[49,49,51],
    0.0078:[50,49,53],
    0.0118:[51,49,56],
    0.0157:[52,50,59],
    0.0196:[53,50,61],
    0.0235:[54,50,64],
    0.0275:[54,51,66],
    0.0314:[55,51,69],
    0.0353:[56,51,71],
    0.0392:[57,52,74],
    0.0431:[57,52,76],
    0.0471:[58,52,79],
    0.051:[59,53,81],
    0.0549:[59,53,84],
    0.0588:[60,54,86],
    0.0627:[60,54,89],
    0.0667:[61,54,91],
    0.0706:[61,55,94],
    0.0745:[62,55,96],
    0.0784:[62,55,99],
    0.0824:[63,56,101],
    0.0863:[63,56,104],
    0.0902:[64,57,106],
    0.0941:[64,57,108],
    0.098:[64,57,111],
    0.102:[65,58,113],
    0.1059:[65,58,116],
    0.1098:[65,59,118],
    0.1137:[66,59,120],
    0.1176:[66,60,123],
    0.1216:[66,60,125],
    0.1255:[66,61,127],
    0.1294:[66,61,130],
    0.1333:[67,62,132],
    0.1373:[67,62,134],
    0.1412:[67,63,137],
    0.1451:[67,63,139],
    0.149:[67,64,141],
    0.1529:[67,64,143],
    0.1569:[67,65,145],
    0.1608:[67,65,147],
    0.1647:[67,66,150],
    0.1686:[67,66,152],
    0.1725:[67,67,154],
    0.1765:[67,67,156],
    0.1804:[67,68,158],
    0.1843:[67,69,160],
    0.1882:[67,69,162],
    0.1922:[66,70,164],
    0.1961:[66,71,166],
    0.2:[66,71,168],
    0.2039:[66,72,169],
    0.2078:[66,73,171],
    0.2118:[66,73,173],
    0.2157:[65,74,175],
    0.2196:[65,75,176],
    0.2235:[65,75,178],
    0.2275:[65,76,180],
    0.2314:[64,77,181],
    0.2353:[64,78,183],
    0.2392:[64,78,184],
    0.2431:[63,79,186],
    0.2471:[63,80,187],
    0.251:[63,81,188],
    0.2549:[62,82,190],
    0.2588:[62,83,191],
    0.2627:[61,83,192],
    0.2667:[61,84,193],
    0.2706:[61,85,194],
    0.2745:[60,86,195],
    0.2784:[60,87,196],
    0.2824:[59,88,197],
    0.2863:[59,89,198],
    0.2902:[58,90,198],
    0.2941:[58,91,199],
    0.298:[58,92,200],
    0.302:[57,93,200],
    0.3059:[57,94,200],
    0.3098:[56,96,201],
    0.3137:[55,97,201],
    0.3176:[55,98,201],
    0.3216:[54,99,201],
    0.3255:[54,100,200],
    0.3294:[53,101,200],
    0.3333:[52,103,200],
    0.3373:[52,104,199],
    0.3412:[51,105,198],
    0.3451:[50,107,197],
    0.349:[49,108,196],
    0.3529:[48,110,195],
    0.3569:[47,111,193],
    0.3608:[46,112,192],
    0.3647:[45,114,190],
    0.3686:[43,116,187],
    0.3725:[42,117,185],
    0.3765:[41,119,183],
    0.3804:[39,120,181],
    0.3843:[38,122,179],
    0.3882:[37,123,176],
    0.3922:[36,124,174],
    0.3961:[36,126,172],
    0.4:[35,127,170],
    0.4039:[34,128,168],
    0.4078:[34,130,166],
    0.4118:[34,131,164],
    0.4157:[34,132,161],
    0.4196:[34,133,159],
    0.4235:[34,134,157],
    0.4275:[35,136,155],
    0.4314:[36,137,153],
    0.4353:[36,138,151],
    0.4392:[37,139,149],
    0.4431:[38,140,147],
    0.4471:[40,141,145],
    0.451:[41,142,143],
    0.4549:[42,143,141],
    0.4588:[44,144,139],
    0.4627:[45,145,137],
    0.4667:[47,146,135],
    0.4706:[49,147,133],
    0.4745:[51,148,131],
    0.4784:[53,149,129],
    0.4824:[55,150,127],
    0.4863:[57,151,125],
    0.4902:[59,152,123],
    0.4941:[61,152,121],
    0.498:[63,153,120],
    0.502:[66,154,118],
    0.5059:[68,155,116],
    0.5098:[70,156,114],
    0.5137:[73,157,112],
    0.5176:[75,157,110],
    0.5216:[78,158,108],
    0.5255:[80,159,106],
    0.5294:[83,159,104],
    0.5333:[85,160,103],
    0.5373:[88,161,101],
    0.5412:[91,162,99],
    0.5451:[93,162,97],
    0.549:[96,163,95],
    0.5529:[99,163,93],
    0.5569:[102,164,91],
    0.5608:[105,165,89],
    0.5647:[108,165,87],
    0.5686:[111,166,86],
    0.5725:[113,166,84],
    0.5765:[116,167,82],
    0.5804:[119,167,80],
    0.5843:[122,168,78],
    0.5882:[124,168,76],
    0.5922:[127,169,74],
    0.5961:[130,169,72],
    0.6:[132,170,71],
    0.6039:[135,170,69],
    0.6078:[137,171,67],
    0.6118:[140,171,65],
    0.6157:[143,172,63],
    0.6196:[145,172,61],
    0.6235:[148,173,59],
    0.6275:[150,173,58],
    0.6314:[152,174,56],
    0.6353:[155,174,54],
    0.6392:[157,174,52],
    0.6431:[160,175,50],
    0.6471:[162,175,48],
    0.651:[165,176,46],
    0.6549:[167,176,45],
    0.6588:[170,176,43],
    0.6627:[172,177,41],
    0.6667:[175,177,39],
    0.6706:[177,178,37],
    0.6745:[180,178,36],
    0.6784:[182,178,34],
    0.6824:[185,179,32],
    0.6863:[187,179,30],
    0.6902:[190,179,29],
    0.6941:[192,179,27],
    0.698:[195,180,25],
    0.702:[197,180,24],
    0.7059:[200,180,23],
    0.7098:[203,180,21],
    0.7137:[205,181,20],
    0.7176:[208,181,19],
    0.7216:[211,181,19],
    0.7255:[213,181,18],
    0.7294:[216,181,18],
    0.7333:[219,181,18],
    0.7373:[221,182,18],
    0.7412:[224,182,19],
    0.7451:[226,182,19],
    0.749:[228,182,19],
    0.7529:[230,183,19],
    0.7569:[232,183,19],
    0.7608:[233,184,20],
    0.7647:[235,184,20],
    0.7686:[236,185,20],
    0.7725:[238,186,20],
    0.7765:[239,186,20],
    0.7804:[240,187,20],
    0.7843:[241,188,20],
    0.7882:[242,188,20],
    0.7922:[243,189,20],
    0.7961:[244,190,20],
    0.8:[245,191,20],
    0.8039:[246,192,20],
    0.8078:[247,193,20],
    0.8118:[247,193,20],
    0.8157:[248,194,20],
    0.8196:[249,195,20],
    0.8235:[249,196,20],
    0.8275:[250,197,20],
    0.8314:[250,198,20],
    0.8353:[251,199,20],
    0.8392:[251,200,20],
    0.8431:[252,201,20],
    0.8471:[252,202,20],
    0.851:[253,203,19],
    0.8549:[253,204,19],
    0.8588:[253,205,19],
    0.8627:[253,207,19],
    0.8667:[254,208,19],
    0.8706:[254,209,19],
    0.8745:[254,210,19],
    0.8784:[254,211,19],
    0.8824:[254,212,18],
    0.8863:[254,213,18],
    0.8902:[255,214,18],
    0.8941:[255,216,18],
    0.898:[255,217,18],
    0.902:[255,218,18],
    0.9059:[255,219,17],
    0.9098:[255,220,17],
    0.9137:[255,221,17],
    0.9176:[255,223,17],
    0.9216:[254,224,16],
    0.9255:[254,225,16],
    0.9294:[254,226,16],
    0.9333:[254,227,16],
    0.9373:[254,229,15],
    0.9412:[254,230,15],
    0.9451:[254,231,15],
    0.949:[253,232,15],
    0.9529:[253,234,14],
    0.9569:[253,235,14],
    0.9608:[253,236,14],
    0.9647:[252,237,13],
    0.9686:[252,239,13],
    0.9725:[252,240,13],
    0.9765:[251,241,12],
    0.9804:[251,242,12],
    0.9843:[250,244,12],
    0.9882:[250,245,11],
    0.9922:[250,246,11],
    0.9961:[249,247,10],
    1.0:[249,249,10]}

let colourKeys = Object.keys(colourScale)

function scaleColour(fraction) {
    var closest = colourKeys.reduce(function(prev, curr) {
      return (Math.abs(curr - fraction) < Math.abs(prev - fraction) ? curr : prev);
    });
    let colour = colourScale[closest];
    return("rgb("+colour+")");
}

let info = [
    {
        country: "ISL",
        path: "m 65.844536,9.6817236 11.210087,6.4721464 -1e-6,12.944293 -11.210087,6.472147 -11.210086,-6.472147 0,-12.944293 z",
        x: 65.5,
        y: 26.4,
        final: true,
        jury: 10,
        televote: 10,
        total: 0
    },
    {
        country: "NOR",
        path: "m 110.68489,9.6817293 11.21009,6.4721467 0,12.944293 -11.21009,6.472146 -11.210086,-6.472146 0,-12.944293 z",
        x: 110.4,
        y: 26.4,
        final: true,
        jury: 36,
        televote: 146,
        total: 0
    },
    {
        country: "FIN",
        path: "m 133.10506,9.6817293 11.21008,6.4721467 0,12.944293 -11.21008,6.472146 -11.21009,-6.472146 0,-12.944293 z",
        x: 133.1,
        y: 26.4,
        final: true,
        jury: 12,
        televote: 26,
        total: 0
    },
    {
        country: "SWE",
        path: "m 121.89497,29.098168 11.21009,6.472147 0,12.944293 -11.21009,6.472146 -11.21008,-6.472146 0,-12.944293 z",
        x: 121.9,
        y: 45.8,
        final: true,
        jury: 258,
        televote: 180,
        total: 0
    },
    {
        country: "EST",
        path: "m 144.31516,29.098168 11.21008,6.472147 0,12.944293 -11.21009,6.472146 -11.21008,-6.472146 0,-12.944293 z",
        x: 144.1,
        y: 45.8,
        final: true,
        jury: 43,
        televote: 98,
        total: 0
    },
    {
        country: "GBR",
        path: "m 43.424363,48.514604 11.210086,6.472146 0,12.944293 -11.210087,6.472147 -11.210087,-6.472147 10e-7,-12.944293 z",
        x: 43.2,
        y: 65.2,
        final: true,
        jury: 283,
        televote: 183,
        total: 0
    },
    {
        country: "DEN",
        path: "m 88.264702,48.514607 11.210087,6.472147 -10e-7,12.944293 -11.210087,6.472146 -11.210086,-6.472146 0,-12.944293 z",
        x: 87.9,
        y: 65.2,
        final: false
    },
    {
        country: "LVA",
        path: "m 155.52522,48.514611 11.21009,6.472147 0,12.944293 -11.21009,6.472146 -11.21008,-6.472147 0,-12.944293 z",
        x: 155.2,
        y: 65.2,
        final: false
    },
    {
        country: "IRL",
        path: "m 32.214268,67.931043 11.210086,6.472146 0,12.944294 -11.210087,6.472146 -11.210086,-6.472147 0,-12.944293 z",
        x: 31.9,
        y: 84.7,
        final: false
    },
    {
        country: "NED",
        path: "m 77.054612,67.93105 11.210086,6.472147 0,12.944293 -11.210087,6.472146 -11.210087,-6.472146 10e-7,-12.944293 z",
        x: 76.9,
        y: 84.7,
        final: true,
        jury: 129,
        televote: 42,
        total: 0
    },
    {
        country: "DEU",
        path: "m 99.4748,67.93105 11.21009,6.472147 0,12.944293 -11.21009,6.472146 -11.210087,-6.472146 10e-7,-12.944293 z",
        x: 99.5,
        y: 84.7,
        final: true,
        jury: 0,
        televote: 6,
        total: 0
    },
    {
        country: "POL",
        path: "m 121.89497,67.93105 11.21009,6.472147 0,12.944293 -11.21009,6.472146 -11.21008,-6.472146 0,-12.944293 z",
        x: 121.7,
        y: 84.7,
        final: true,
        jury: 46,
        televote: 105,
        total: 0
    },
    {
        country: "LTU",
        path: "m 144.31516,67.93105 11.21008,6.472147 0,12.944293 -11.21009,6.472146 -11.21008,-6.472146 0,-12.944293 z",
        x: 144.3,
        y: 84.7,
        final: true,
        jury: 35,
        televote: 93,
        total: 0
    },
    {
        country: "UKR",
        path: "m 166.73531,67.93105 11.21008,6.472147 0,12.944293 -11.21008,6.472146 -11.21009,-6.472146 0,-12.944293 z",
        x: 166.4,
        y: 84.7,
        final: true,
        jury: 192,
        televote: 439,
        total: 0
    },
    {
        country: "BEL",
        path: "m 65.844521,87.347486 11.210086,6.472146 0,12.944298 -11.210087,6.47214 -11.210086,-6.47215 0,-12.944288 z",
        x: 65.6,
        y: 104.1,
        final: true,
        jury: 59,
        televote: 5,
        total: 0
    },
    {
        country: "AUT",
        path: "m 88.264695,87.347486 11.210086,6.472146 0,12.944298 -11.210087,6.47214 -11.210087,-6.47215 10e-7,-12.944288 z",
        x: 88.3,
        y: 104.1,
        final: false
    },
    {
        country: "CZE",
        path: "m 110.68489,87.347486 11.21009,6.472146 0,12.944298 -11.21009,6.47214 -11.210086,-6.47215 0,-12.944288 z",
        x: 110.7,
        y: 104.1,
        final: true,
        jury: 33,
        televote: 5,
        total: 0
    },
    {
        country: "SRB",
        path: "m 133.10506,87.347486 11.21008,6.472146 0,12.944298 -11.21008,6.47214 -11.21009,-6.47215 0,-12.944288 z",
        x: 133.1,
        y: 104.1,
        final: true,
        jury: 87,
        televote: 225,
        total: 0
    },
    {
        country: "ROU",
        path: "m 155.52522,87.347486 11.21009,6.472146 0,12.944298 -11.21009,6.47214 -11.21008,-6.47215 0,-12.944288 z",
        x: 155.5,
        y: 104.1,
        final: true,
        jury: 12,
        televote: 53,
        total: 0
    },
    {
        country: "MDA",
        path: "m 177.94537,87.347478 11.21009,6.472147 0,12.944295 -11.21009,6.47214 -11.21008,-6.47214 0,-12.944296 z",
        x: 177.6,
        y: 104.1,
        final: true,
        jury: 14,
        televote: 239,
        total: 0
    },
    {
        country: "FRA",
        path: "m 54.634434,106.76392 11.210087,6.47215 -10e-7,12.94429 -11.210087,6.47215 -11.210086,-6.47215 0,-12.94429 z",
        x: 54.3,
        y: 123.5,
        final: true,
        jury: 9,
        televote: 8,
        total: 0
    },
    {
        country: "SUI",
        path: "m 77.054604,106.76392 11.210086,6.47215 0,12.94429 -11.210087,6.47215 -11.210086,-6.47215 0,-12.94429 z",
        x: 76.6,
        y: 123.5,
        final: true,
        jury: 78,
        televote: 0,
        total: 0
    },
    {
        country: "SVN",
        path: "m 99.474778,106.76393 11.210082,6.47215 0,12.94429 -11.210083,6.47214 -11.210087,-6.47214 10e-7,-12.9443 z",
        x: 99.6,
        y: 123.5,
        final: false
    },
    {
        country: "CRO",
        path: "m 121.89497,106.76393 11.21009,6.47215 0,12.94429 -11.21009,6.47214 -11.21008,-6.47214 0,-12.9443 z",
        x: 121.9,
        y: 123.5,
        final: false
    },
    {
        country: "MNE",
        path: "m 144.31516,106.76393 11.21008,6.47215 0,12.94429 -11.21009,6.47214 -11.21008,-6.47214 0,-12.9443 z",
        x: 144.2,
        y: 123.5,
        final: false
    },
    {
        country: "BUL",
        path: "m 166.73531,106.76392 11.21008,6.47215 0,12.94429 -11.21008,6.47215 -11.21009,-6.47215 0,-12.94429 z",
        x: 166.5,
        y: 123.5,
        final: false
    },
    {
        country: "GEO",
        path: "m 211.57565,106.76392 11.21009,6.47215 0,12.94429 -11.21009,6.47215 -11.21008,-6.47215 0,-12.94429 z",
        x: 211.6,
        y: 123.5,
        final: false
    },
    {
        country: "AZE",
        path: "m 233.99583,106.76392 11.21009,6.47215 0,12.94429 -11.21009,6.47215 -11.21008,-6.47215 0,-12.94429 z",
        x: 234.2,
        y: 123.5,
        final: true,
        jury: 103,
        televote: 3,
        total: 0
    },
    {
        country: "POR",
        path: "m 21.004174,126.18036 11.210086,6.47214 0,12.9443 -11.210087,6.47214 -11.2100865,-6.47214 4e-7,-12.9443 z",
        x: 20.7,
        y: 142.9,
        final: true,
        jury: 171,
        televote: 36,
        total: 0
    },
    {
        country: "ESP",
        path: "m 43.424347,126.18036 11.210087,6.47214 -10e-7,12.9443 -11.210086,6.47214 -11.210087,-6.47214 10e-7,-12.9443 z",
        x: 43.2,
        y: 142.9,
        final: true,
        jury: 231,
        televote: 228,
        total: 0
    },
    {
        country: "SMR",
        path: "m 88.264687,126.18036 11.210086,6.47214 0,12.9443 -11.210087,6.47214 -11.210086,-6.47214 0,-12.9443 z",
        x: 88.1,
        y: 142.9,
        final: false
    },
    {
        country: "ALB",
        path: "m 133.10506,126.18036 11.21008,6.47214 0,12.9443 -11.21008,6.47214 -11.21009,-6.47214 0,-12.9443 z",
        x: 133.3,
        y: 142.9,
        final: false
    },
    {
        country: "MKD",
        path: "m 155.52522,126.18036 11.21009,6.47214 0,12.9443 -11.21009,6.47214 -11.21008,-6.47214 0,-12.9443 z",
        x: 155.4,
        y: 142.9,
        final: false
    },
    {
        country: "ARM",
        path: "m 222.78574,126.18036 11.21008,6.47214 0,12.9443 -11.21008,6.47214 -11.21009,-6.47214 0,-12.9443 z",
        x: 223.1,
        y: 142.9,
        final: true,
        jury: 40,
        televote: 21,
        total: 0
    },
    {
        country: "ITA",
        path: "m 99.47477,145.59681 11.21009,6.47215 0,12.94429 -11.210091,6.47215 -11.210086,-6.47215 0,-12.94429 z",
        x: 99.0,
        y: 162.3,
        final: true,
        jury: 158,
        televote: 110,
        total: 0
    },
    {
        country: "GRE",
        path: "m 144.31512,145.59681 11.21009,6.47215 0,12.94429 -11.21009,6.47215 -11.21008,-6.47215 0,-12.94429 z",
        x: 144.3,
        y: 162.3,
        final: true,
        jury: 158,
        televote: 57,
        total: 0
    },
    {
        country: "CYP",
        path: "m 189.15546,145.59681 11.21008,6.47215 0,12.94429 -11.21008,6.47215 -11.21009,-6.47215 0,-12.94429 z",
        x: 189.1,
        y: 162.3,
        final: false
    },
    {
        country: "AUS",
        path: "m 222.78574,165.01324 11.21008,6.47215 0,12.94429 -11.21008,6.47215 -11.21009,-6.47215 0,-12.94429 z",
        x: 222.9,
        y: 181.7,
        final: true,
        jury: 123,
        televote: 2,
        total: 0
    },
    {
        country: "MLT",
        path: "m 77.054604,184.42968 11.210086,6.47215 0,12.94429 -11.210087,6.47215 -11.210086,-6.47215 0,-12.94429 z",
        x: 76.8,
        y: 201.2,
        final: false
    },
    {
        country: "ISR",
        path: "m 189.15546,184.42968 11.21008,6.47215 0,12.94429 -11.21008,6.47215 -11.21009,-6.47215 0,-12.94429 z",
        x: 188.7,
        y: 201.2,
        final: false
    }
]

const winner = 631;

export default function Index() {
    console.log(Object.values(colourScale));
  return (
    <Layout>
        <h1>Eurovision 2022!</h1>
        <h2>Points allocated:</h2>
        <svg id="eurovision" viewBox="0 0 255 220">
            {info.map(country =>
            <g id={country.country} className={country.final ? "final" : "eliminated"}>
                <path className="country" d={country.path} fill={country.final ? scaleColour((country.jury + country.televote) / winner) : "#404040"} />
                <text className="label" x={country.x} y={country.y}>{country.country}</text>
            </g>
            )}
        <text className="rampLabel" x="215" y="20">{winner}</text>
        {Object.keys(colourScale).sort((a,b) => a-b).map((key, idx) =>
            <rect fill={"rgb(" + colourScale[key] + ")"} width="20" height="0.3" x="205" y={88 - 0.25*idx} />
                )}
        </svg>
    </Layout>
  )
}